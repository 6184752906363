<template>
  <div>
    <div>
      <Heading heading="h4" content="Roster">
        <template slot="button">
          <router-link
            class="ml-2"
            :to="{
              name: 'EducatorClassRosterEdit',
              params: { id: $route.params.id }
            }"
          >
            <el-button type="success" size="mini">
              <i class="fas fa-edit" />
              {{ $t("TeacherCourses.Edit") }}
            </el-button>
          </router-link>
        </template>
      </Heading>
      <hr />
      <div class="ml-3">
        <!-- <Heading heading="h6" content="Teacher(s)"> </Heading>
        <el-table
          v-if="course && course.users"
          border
          :data="course.users"
          class="mb-3 mt-2"
        >
          <el-table-column :label="$t('TeacherCourses.Teacher')" prop="">
            <template slot-scope="scope">
              <router-link
                v-if="isAdmin"
                :to="{ name: 'AdminViewUser', params: { id: scope.row.id } }"
              >
                <b>
                  {{
                    user.displayName(scope.row.first_name, scope.row.last_name)
                  }}
                </b>
              </router-link>
              <b v-else>
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </b>
            </template>
          </el-table-column>
          <el-table-column :label="$t('login.email')" prop="email">
          </el-table-column>
          <el-table-column :label="$t('TeacherCourses.Register Date')">
            <template slot-scope="scope">
              {{ instant.formatGoogleTime(scope.row.created_at) }}
            </template>
          </el-table-column>
        </el-table> -->
        <Heading heading="h6" content="Student(s)"></Heading>
        <template>
          <el-table
            border
            v-if="course"
            :data="getStudents(course.users)"
            class="mb-3 mt-2"
          >
            <el-table-column :label="$t('TeacherCourses.Students')" prop="">
              <template slot-scope="scope">
                <b class="text-success">
                  {{
                    user.displayName(scope.row.first_name, scope.row.last_name)
                  }}
                </b>
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Join Date')">
              <template slot-scope="scope">
                <b class="text-success">
                  {{ instant.formatGoogleTime(scope.row.updated_at) }}
                </b>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant, helper } from "@ivy-way/material";
import Class from "@/views/courses/apis/Add.js";

export default {
  metaInfo() {},

  components: {},

  mixins: [],
  props: [],
  data() {
    return {
      course: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    helper() {
      return helper;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    },
    isAdmin() {
      return false;
    },
    isStudent() {
      return false;
    }
  },
  watch: {},

  mounted() {
    this.getClass(this.$route.params.id);
  },

  methods: {
    getStudents(users) {
      if (users["member"]) {
        return users["member"];
      } else {
        return [];
      }
    },
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.course = res;
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  padding: 0 !important;
  margin: 0 !important;
  /* font-size: 1.1rem; */
  /* font-weight: 500; */
  line-height: 1.1rem;
}
</style>
