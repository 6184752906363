import ApiService from "@/common/api.service";

export default {
  getFiles(data) {
    return ApiService.query("/teaching-resources", data);
  },
  addFile(data) {
    return ApiService.post("/teaching-resources", data);
  },
  removeFile(id) {
    return ApiService.delete("/teaching-resources" + id);
  },
  getExams(data) {
    return ApiService.query("/exams", data);
  },
  getExam(examId) {
    return ApiService.post(`/exams/${examId}/purchase`);
  },
  getFileDetail(id) {
    return ApiService.query(`teaching-resources/${id}`);
  },
  bindExamToClasses(id, data) {
    return ApiService.post(`/teaching-resources/${id}/user-exams`, data);
  },
  getFileBySlug(slug) {
    return ApiService.download(`files/${slug}`);
  },
  getUserExamsOld(id) {
    return ApiService.query(`teaching-resources/${id}/all-user-exams`);
  },
  getUserExams(id) {
    return ApiService.query(`teaching-resources/${id}/user-exam-progress`);
  },
  downloadFileBySlug(slug) {
    return ApiService.download(`files/${slug}/download`);
  },
  uploadUrl(data) {
    return ApiService.post("urls", data);
  }
};
