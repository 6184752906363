var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-collapse',{attrs:{"accordion":""},on:{"change":function($event){return _vm.getFolderFiles(_vm.Files, _vm.FileIndexNow)}},model:{value:(_vm.FileIndexNow),callback:function ($$v) {_vm.FileIndexNow=$$v},expression:"FileIndexNow"}},[_c('draggable',_vm._l((_vm.Files),function(File,index){return _c('div',{key:File.id,staticClass:"file-style"},[(File.type === 'folder')?_c('div',{class:{
            'folder-active': _vm.FileIndexNow === index,
            folder: true
          }},[_c('el-collapse-item',{attrs:{"name":index}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","justify-content":"space-between"}},[_c('div',{staticStyle:{"font-size":"1rem"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.FileIndexNow !== index),expression:"FileIndexNow !== index"}]},[_c('i',{staticClass:"fas fa-folder"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.FileIndexNow === index),expression:"FileIndexNow === index"}]},[_c('i',{staticClass:"fas fa-folder-open"})]),_vm._v("   "+_vm._s(`${File.name}`)+" ")]),_c('div',{staticClass:"d-flex mr-2"},[_c('el-dropdown',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isView),expression:"!isView"}],attrs:{"trigger":"click"}},[_c('span',{staticClass:"more-icon",on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fa-solid fa-ellipsis-vertical"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('createNewFile', File.id, File)}}},[_vm._v(" Add New ")])]),_c('el-dropdown-item',[_c('div',[_vm._v(" "+_vm._s(_vm.$t("newCourses.Delete"))+" ")])])],1)],1)],1)])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!File.hasOwnProperty('files')),expression:"!File.hasOwnProperty('files')"}],staticClass:"subject-children"},[(File.files)?_c('div',[(File.files.length > 0)?_c('div',[_c('FileListItem',{staticClass:"p-2 pl-4",attrs:{"Files":File.files,"isView":_vm.isView},on:{"createNewFile":_vm.createNewFile,"viewFile":_vm.viewFile}})],1):_c('el-empty',{attrs:{"description":"No Materials","image-size":50}})],1):_vm._e()])],2)],1):_c('div',[_c('div',{class:{
              'file-active': _vm.FileIndexNow === index,
              'section-season': true,
              'pl-2': true,
              'pr-2': true
            },staticStyle:{"align-items":"center","display":"flex","justify-content":"space-between"},on:{"click":function($event){return _vm.viewFileDetail(File, index)}}},[_c('div',{staticClass:"p-2",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"season-title m-0",staticStyle:{"width":"100%"}},[_c('b',{directives:[{name:"show",rawName:"v-show",value:(File.type === 'document'),expression:"File.type === 'document'"}]},[_c('i',{staticClass:"fas fa-file"}),_vm._v(" File ")]),_c('b',{directives:[{name:"show",rawName:"v-show",value:(File.type === 'link'),expression:"File.type === 'link'"}]},[_c('i',{staticClass:"fas fa-link"}),_vm._v(" Link ")]),_c('b',{directives:[{name:"show",rawName:"v-show",value:(File.type === 'video'),expression:"File.type === 'video'"}]},[_c('i',{staticClass:"fab fa-youtube"}),_vm._v(" Youtube Video ")]),_c('b',{directives:[{name:"show",rawName:"v-show",value:(File.type === 'exam'),expression:"File.type === 'exam'"}]},[_c('i',{staticClass:"fas fa-chart-bar"}),_vm._v(" Test ")])]),_c('div',{staticClass:"season-info"},[_c('b',[_vm._v(_vm._s(File.name))])])]),_c('div',[_c('el-dropdown',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isView),expression:"!isView"}],attrs:{"trigger":"click"}},[_c('span',{staticClass:"more more-icon",on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fa-solid fa-ellipsis-vertical"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(File.type === 'exam')?_c('el-dropdown-item',[_c('div',{on:{"click":function($event){return _vm.removeTest(File.id, File.session_class_assign_test_id)}}},[_vm._v(" "+_vm._s(_vm.$t("newCourses.Delete"))+" ")])]):_c('el-dropdown-item',[_c('div',{on:{"click":function($event){return _vm.removeFile(File.id)}}},[_vm._v(" "+_vm._s(_vm.$t("newCourses.Delete"))+" ")])])],1)],1)],1)])])])}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }