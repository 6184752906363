<template>
  <div>
    <Heading heading="h4" :content="$t('TeacherCourses.Info')">
      <template slot="button">
        <router-link
          class="ml-2"
          :to="{
            name: 'EducatorClassInfoEdit',
            params: { id: $route.params.id }
          }"
        >
          <el-button type="success" size="mini">
            <i class="fas fa-edit" />
            {{ $t("TeacherCourses.Edit") }}
          </el-button>
        </router-link>
      </template>
    </Heading>
    <hr />
    <el-form label-width="140px">
      <el-form-item
        v-if="course && course.teacher_users"
        :label="$t('TeacherCourses.Teacher')"
        prop="title"
      >
        <b
          class="mr-1"
          v-for="(teacher, index) in course.teacher_users"
          :key="teacher.id"
        >
          <router-link
            v-if="isAdmin"
            :to="{ name: 'AdminViewUser', params: { id: teacher.id } }"
          >
            {{ user.displayName(teacher.first_name, teacher.last_name) }}
          </router-link>
          <template v-else>
            <span class="text-success">
              {{ user.displayName(teacher.first_name, teacher.last_name) }}
            </span>
          </template>
          <span v-if="index < course.teacher_users.length - 1">
            ,
          </span>
        </b>
      </el-form-item>
      <el-form-item :label="$t('TeacherCourses.Class Name')" prop="title">
        <b class="text-success">
          {{ course.name }}
        </b>
      </el-form-item>
      <el-form-item
        :label="$t('TeacherCourses.Class Description')"
        prop="title"
      >
        <span class="text-success">
          <b v-if="course.description">{{ course.description }}</b>
          <b v-else>-</b>
        </span>
      </el-form-item>
      <!-- <el-form-item :label="$t('TeacherCourses.Method')" prop="type">
          <b class="text-success" v-if="course.is_online">
            <i class="fa fa-globe"></i>
            {{ $t("TeacherCourses.Online") }}
          </b>
          <b class="text-success" v-else>
            <i aria-hidden="true" class="fa fa-building"></i>
            {{ $t("TeacherCourses.In-Person") }}
          </b>
        </el-form-item> -->
      <el-form-item :label="$t('TeacherCourses.Class Dates')" prop="date">
        <b class="text-success">
          <template v-if="course.started_at">
            <span v-if="course.started_at">{{
              instant.formatGoogleTime(course.started_at)
            }}</span>
            <span v-if="course.ended_at">
              ~
              {{ instant.formatGoogleTime(course.ended_at) }}
            </span>
          </template>
          <template v-else>
            -
          </template>
        </b>
      </el-form-item>
      <el-form-item :label="$t('TeacherCourses.Class Time')" prop="time">
        <b class="text-success">
          <template v-if="course.started_time">
            <span>{{ course.started_time }}</span>
            <span v-if="course.ended_time">
              ~
              {{ course.ended_time }}
            </span>
          </template>
          <template v-else>
            -
          </template>
        </b>
      </el-form-item>
      <!-- <el-form-item
          :label="$t('TeacherCourses.Number of Students')"
          prop="student_users_count"
        >
          <b class="text-success">
            {{ course.student_users.length }}
          </b>
        </el-form-item> -->
      <el-form-item
        :label="$t('TeacherCourses.Invite Students')"
        prop="student_users_count"
        v-if="!isStudent"
      >
        <b class="text-success mr-2">
          {{ course.slug }}
        </b>
        <el-tooltip
          class="mr-2"
          effect="dark"
          :content="$t('TeacherCourses.Invitation Link')"
          placement="top"
        >
          <el-button type="success" size="mini" @click="setLink(course.slug)">
            <i class="fas fa-user-plus"></i>
          </el-button>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <el-dialog
      class="class-link"
      :title="`Class Invitation Link`"
      :visible.sync="showLink"
      width="50%"
    >
      <p>{{ $t("TeacherCourses.Copy and send this link to students") }}</p>
      <div class="text-center">
        <div class="referral-code">
          <a>
            <span>
              {{ link }}
            </span>
          </a>
          <el-button class="ml-3" type="success" @click="copyLink">
            <i class="fas fa-copy"></i>
            Copy Link
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant, helper } from "@ivy-way/material";;
import Class from "@/views/courses/apis/Add.js";
import baseDomainPath from "@/common/baseDomainPath";

export default {
  metaInfo() {},

  components: {},

  mixins: [],
  props: [],
  data() {
    return {
      course: null,
      showLink: false
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    helper() {
      return helper;
    }
  },
  watch: {},

  mounted() {
    this.getClass(this.$route.params.id);
  },

  methods: {
    setLink(code) {
      console.log(code);
      this.linkType = "Class";
      this.link = `${baseDomainPath}student/classes/join?code=${code}`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    },
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.course = res;
    }
  }
};
</script>

<style scoped></style>
