<template>
  <div>
    <el-table :data="myPlans" style="width: 100%">
      <el-table-column label="Plan">
        <template slot-scope="scope">
          <b class="test-color">
            {{ scope.row.subscription_plan.name }}
          </b>
        </template>
      </el-table-column>
      <el-table-column label="Type">
        <template slot-scope="scope">
          <b>{{ scope.row.subscription_plan_price.nickname }}</b>
        </template>
      </el-table-column>
      <el-table-column label="Price">
        <template slot-scope="scope">
          <b>${{ scope.row.subscription_plan_price.amount }}</b>
        </template>
      </el-table-column>
      <el-table-column label="Validity period">
        <template slot-scope="scope">
          <span>
            <b>{{ moment(scope.row.period_start).format("YYYY-MM-DD") }}</b>
            ~ <br />
            <b>{{ moment(scope.row.period_end).format("YYYY-MM-DD") }}</b>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Next subscription date">
        <template slot-scope="scope">
          <template v-if="scope.row.cancel_at_period_end === 0">
            <b>
              {{ moment(scope.row.period_end).format("YYYY-MM-DD") }}
            </b>
          </template>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="Automatic subscription">
        <template slot-scope="scope">
          <template v-if="scope.row.cancel_at_period_end === 0">
            <b class="text-success">
              <i class="fa fa-check-circle"></i>
            </b>
          </template>
          <el-template v-else>
            <span>-</span>
          </el-template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import moment from "moment";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: ["myPlans"],
  data() {
    return {
      moment
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    }
  },
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped></style>
