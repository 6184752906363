var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-3"},[_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.myTitle)+" ")])],1)],2),_c('Heading',{attrs:{"heading":"h4","content":_vm.myTitle}}),_c('hr'),(_vm.companies)?[(_vm.resultsTotal > -1)?_c('el-alert',{staticClass:"mt-3",attrs:{"type":"success","closable":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Results: "),_c('b',[_vm._v(_vm._s(_vm.resultsTotal))])])]):_vm._e(),_c('div',{staticClass:"text-center",staticStyle:{"margin":"15px 0"}},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.pageSize,"current-page":_vm.page,"total":_vm.resultsTotal,"pager-count":9,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1),(_vm.companies)?_c('el-table',{attrs:{"data":_vm.companies,"stripe":""}},[_c('el-table-column',{attrs:{"label":"Company Name","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
              name: 'CompanyInfo',
              params: {
                id: scope.row.id
              }
            }}},[_c('b',[_vm._v(_vm._s(scope.row.name))])])]}}],null,false,2384174983)}),_c('el-table-column',{attrs:{"label":"Plan Points","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',{staticClass:"text-success"},[_vm._v(" "+_vm._s(scope.row.subscription_quota)+" ")]),_c('el-button',{staticClass:"ml-2",attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.selectCompany(scope.row)}}},[_c('i',{staticClass:"fas fa-edit"})])]}}],null,false,2478110706)}),_c('el-table-column',{attrs:{"label":_vm.$t('TeacherCourses.Class Dates'),"width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.created_at)?[_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime(scope.row.created_at))+" ")]:[_vm._v(" - ")]]}}],null,false,3511058903)})],1):_vm._e(),_c('div',{staticClass:"text-center",staticStyle:{"margin":"15px 0"}},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.pageSize,"current-page":_vm.page,"total":_vm.resultsTotal,"pager-count":9,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1)]:_vm._e(),(_vm.company)?_c('el-dialog',{attrs:{"title":"Add Plan Points","visible":_vm.showCompany,"width":"60%"},on:{"update:visible":function($event){_vm.showCompany=$event}}},[_c('el-form',{attrs:{"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"Balance"}},[_c('b',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.form.oSubscription_quota)+" "),(_vm.form.oSubscription_quota > 1)?_c('small',[_vm._v(" Points")]):(
              _vm.form.oSubscription_quota > -1 && _vm.form.oSubscription_quota < 2
            )?_c('small',[_vm._v(" Point ")]):_vm._e()])]),_c('el-form-item',{attrs:{"label":""}},[_c('el-input-number',{staticStyle:{"width":"100%"},attrs:{"step":10},model:{value:(_vm.form.subscription_quota),callback:function ($$v) {_vm.$set(_vm.form, "subscription_quota", $$v)},expression:"form.subscription_quota"}})],1),_c('el-form-item',[_c('div',{staticClass:"text-right"},[_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.givePoints}},[_vm._v(" Add ")])],1)])],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }