<template>
  <div class="container pt-3">
    <Breadcrumb class="m-0" >
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'StudentClassesList'
            }"
          >
            My Classes
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <el-skeleton class="mt-3" :loading="!myTitle" animated>
      <template slot="template">
        <el-skeleton-item style="line-height:1.5;width: 50%;" />
      </template>
      <template>
        <Heading heading="h4" :content="myTitle"></Heading>
      </template>
    </el-skeleton>
    <hr />
    <el-menu
      :default-active="activeSection"
      class="course-menu mb-3 mt-3"
      mode="horizontal"
      @select="showSection"
      background-color="#f3f3f3"
      :active-text-color="themeColor"
    >
      <el-menu-item index="StudentClassInfo">
        <i class="el-icon-s-home"></i>
        {{ $t("TeacherCourses.Info") }}
      </el-menu-item>
      <el-menu-item index="StudentClassMaterials">
        <i class="el-icon-user-solid"></i>
        Materials
      </el-menu-item>
      <el-menu-item index="StudentClassTests">
        <i class="el-icon-s-data"></i>
        Tests
      </el-menu-item>
      <!--
      <el-menu-item
        index="practices"
        :route="{ query: { active: 'practices' } }"
      >
        <i class="el-icon-s-data"></i>
        Modules & Drills
      </el-menu-item> -->
      <el-menu-item index="lessons" v-if="false">
        <i class="el-icon-date"></i>
        Lessons
      </el-menu-item>
    </el-menu>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import role from "@/mixins/role.js";
import OnlineSatScore from "@/views/courses/components/SATScore";
import { instant, user } from "@ivy-way/material";
import Common from "@/mixins/common.js";
// import Class from "@/apis/class.js";
import Class from "@/views/courses/apis/Add.js";
import baseDomainPath from "@/common/baseDomainPath";
// import Lessons from "@/views/videoClasses/Detail.vue";
import moment from "moment";
// import TestResultsTable from "@/views/satTestResultsPage/components/TestResultsTable";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      moment,
      linkType: "Class",
      linkTypeTitle: null,
      newTest: {
        isNew: 1,
        subject: null,
        title: ""
      },
      subjects: [],
      activeSection: "details",
      course: null,
      displayStudents: null,
      weeklyScores: null,
      showForStudent: true,
      showLink: false,
      showSelectExam: false,
      studentExams: [],
      nowExamIndex: null,
      nowSessionClassExamId: null
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    nowExam() {
      let res = null;
      if (this.nowExamIndex > -1) {
        res = this.studentExams[this.nowExamIndex];
      }
      return res;
    },
    analysisRouter() {
      let router = "TeacherAnalysis";
      if (this.isAdmin) {
        router = "AdminAnalysis";
      }
      return router;
    },
    adaptiveAnalysisRouter() {
      let router = "TeacherAdaptiveAnalysis";
      if (this.isAdmin) {
        router = "AdminAdaptiveAnalysis";
      }
      return router;
    },
    editCourseLink() {
      if (this.isAdmin) {
        return "AdminEditCourse";
      } else if (this.isTeacher) {
        return "TeacherEditCourse";
      } else if (this.isCompany) {
        return "CompanyEditCourse";
      } else {
        return "AdminEditCourse";
      }
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    isStudent() {
      return this.$route.name === "StudentCourseDetail";
    },
    isTeacher() {
      return this.$route.name === "TeacherCourseDetail";
    },
    isAdmin() {
      return this.$route.name === "AdminCourseDetail";
    },
    isCompany() {
      return this.$route.name === "CompanyCourseDetail";
    },
    instant() {
      return instant;
    },
    user() {
      return user;
    },
    myTitle() {
      return this.course ? this.course.name : "";
    },
    courseId() {
      return this.$route.params.id;
    },
    practiceType() {
      if (this.activeSection === "tests") {
        return "composePractices";
      } else {
        return "singlePractice";
      }
    }
  },
  watch: {},

  async mounted() {
    this.activeSection = this.$route.name;
    console.log(this.activeSection);
    if (this.$route.params.id) {
      this.reset();
    }
    // if (this.$route.query.active) {
    //   this.activeSection = this.$route.query.active;
    // }
    this.subjects = [
      {
        id: 26,
        name: "333",
        materials: [
          {
            id: 77,
            material_subject_id: 26,
            title: "333",
            description: "33333",
            publish_at: "2023-09-06",
            material_files: []
          }
        ]
      },
      {
        id: 27,
        name: "444",
        materials: [
          {
            id: 78,
            material_subject_id: 27,
            title: "23123",
            description: "231232312323123",
            publish_at: "2023-09-06",
            material_files: [
              {
                id: 266,
                material_id: 78,
                type: "link",
                name: "https://www.baidu.com/",
                link: "https://www.baidu.com/",
                extension: null
              }
            ]
          },
          {
            id: 79,
            material_subject_id: 27,
            title: "333333333",
            description: "333333333",
            publish_at: "2023-09-06",
            material_files: [
              {
                id: 267,
                material_id: 79,
                type: "link",
                name: "https://translate.google.com.hk/",
                link: "https://translate.google.com.hk/",
                extension: null
              }
            ]
          }
        ]
      }
    ];
  },

  methods: {
    selectExam(sessionClassExam, user_id) {
      this.studentExams = null;
      this.nowSessionClassExamId = sessionClassExam;
      this.getStudentsExams(sessionClassExam, user_id);
      this.showSelectExam = true;
    },
    async getStudentsExams(sessionClassExam, user_id) {
      const res = await Class.getStudentsExams(sessionClassExam, { user_id });
      this.studentExams = res.user_exams;
      this.nowExamIndex = null;
    },
    async reSetTest(user_exam_id) {
      const res = await Class.reSetTest(this.nowSessionClassExamId, {
        user_exam_id
      });
      this.reset();
      this.showSelectExam = false;
    },
    getPracticeType(type) {
      if (type === "Modules\\Practice\\Entities\\ComposePractice") {
        return "composePractices";
      } else if (type === "App\\Entities\\AdaptivePractice") {
        return "adaptivePractices";
      } else if (type === "Modules\\Practice\\Entities\\Practice") {
        return "singlePractice";
      }
    },
    getBrowse(type) {
      console.log(type);
      if (type === "Modules\\Practice\\Entities\\ComposePractice") {
        return "SatComposePracticeDetailAll";
      } else if (type === "Modules\\Practice\\Entities\\Practice") {
        return "SatPracticeDetailAll";
      } else if (type === "App\\Entities\\AdaptivePractice") {
        return "SatAdaptiveDetailAll";
      }
    },
    getMe(arr) {
      return arr.filter(item => item.id === this.getProfile.id);
    },
    showTest(test) {
      const OLD_PRACTICE_READING = 1;
      const OLD_PRACTICE_WRITING = 2;
      const OLD_PRACTICE_MATH = 3;
      const OLD_PRACTICE_MATH_CALC = 4;
      const OLD_DRILL_READING = 5;
      const OLD_DRILL_WRITING = 6;
      const OLD_DRILL_MATH = 7;
      const OLD_DRILL_MATH_CALC = 8;
      const OLD_FULL_TEST = 9;
      const OLD_ADAPTIVE_PRACTICE = 10;

      const DRILL_PRACTICE_ENGLISH = 11;
      const DRILL_PRACTICE_MATH = 12;
      const MODULE_PRACTICE_ENGLISH = 13;
      const MODULE_PRACTICE_MATH = 14;
      const FULL_TEST = 15;
      const ADAPTIVE_PRACTICE = 16;

      if (this.activeSection === "tests") {
        if (test.category == 15 || test.category == 16) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          test.category == 11 ||
          test.category == 12 ||
          test.category == 13 ||
          test.category == 14
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    showPractice(test) {
      console.log(test);
      if (test.test.marks[1]) {
        return true;
      } else {
        return false;
      }
    },
    inTime(time) {
      console.log(moment(time).format("YYYY-MM-DD HH:mm"));
      console.log(moment().format("YYYY-MM-DD HH:mm"));
      return (
        moment(time).format("YYYY-MM-DD HH:mm") <=
        moment().format("YYYY-MM-DD HH:mm")
      );
    },
    async reset() {
      await this.getClass(this.$route.params.id);
      // await this.getClassExamOverView(this.$route.params.id);
      await this.setClasses();
      await this.getAvg();
    },
    setClasses() {
      this.course["checkedAll"] = true;
      (this.displayStudents || []).forEach(student => {
        student["is_checked"] = true;

        let old_escore = 0;
        let old_mscore = 0;
        let old_score = 0;

        let first_escore = 0;
        let first_mscore = 0;
        let first_score = 0;

        let max_escore = 0;
        let max_mscore = 0;
        let max_score = 0;

        let count = 0;
        this.course.session_class_exams.forEach((exam, index) => {
          exam["count"] = 0;

          exam["e_total"] = 0;
          exam["m_total"] = 0;
          exam["t_total"] = 0;

          exam["e_up"] = 0;
          exam["m_up"] = 0;
          exam["t_up"] = 0;

          if (
            student.student_exams !== undefined &&
            student.student_exams["Test" + index] !== undefined
          ) {
            if (
              student.student_exams["Test" + index].user_exam &&
              student.student_exams["Test" + index].user_exam.result
            ) {
              count++;
              // student.student_exams["Test" + index].user_exam.result
              if (count === 1) {
                student.student_exams["Test" + index].user_exam.result[
                  "escore_up"
                ] = 0;
                student.student_exams["Test" + index].user_exam.result[
                  "mscore_up"
                ] = 0;
                student.student_exams["Test" + index].user_exam.result[
                  "score_up"
                ] = 0;
                first_escore =
                  student.student_exams["Test" + index].user_exam.result
                    .english_score;
                first_mscore =
                  student.student_exams["Test" + index].user_exam.result
                    .math_score;
                first_score =
                  student.student_exams["Test" + index].user_exam.total_score;
              } else {
                student.student_exams["Test" + index].user_exam.result[
                  "escore_up"
                ] =
                  student.student_exams["Test" + index].user_exam.result
                    .english_score - old_escore;
                student.student_exams["Test" + index].user_exam.result[
                  "mscore_up"
                ] =
                  student.student_exams["Test" + index].user_exam.result
                    .math_score - old_mscore;
                student.student_exams["Test" + index].user_exam.result[
                  "score_up"
                ] =
                  student.student_exams["Test" + index].user_exam.total_score -
                  old_score;
              }
              //Save Old Score
              old_escore =
                student.student_exams["Test" + index].user_exam.result
                  .english_score;
              old_mscore =
                student.student_exams["Test" + index].user_exam.result
                  .math_score;
              old_score =
                student.student_exams["Test" + index].user_exam.total_score;
              // Super score
              if (
                student.student_exams["Test" + index].user_exam.result
                  .english_score > max_escore
              ) {
                max_escore =
                  student.student_exams["Test" + index].user_exam.result
                    .english_score;
              }

              if (
                student.student_exams["Test" + index].user_exam.result
                  .math_score > max_mscore
              ) {
                max_mscore =
                  student.student_exams["Test" + index].user_exam.result
                    .math_score;
              }
            }
          }
        });
        max_score = max_escore + max_mscore;

        let max_escore_up = max_escore - first_escore;
        let max_mscore_up = max_mscore - first_mscore;
        let max_score_up = max_score - first_score;
        student["max_escore"] = max_escore;
        student["max_mscore"] = max_mscore;
        student["max_score"] = max_score;

        student["max_escore_up"] = max_escore_up;
        student["max_mscore_up"] = max_mscore_up;
        student["max_score_up"] = max_score_up;
      });
      this.course = JSON.parse(JSON.stringify(this.course));
      // this.getAvg(sectionClass);
      // return sectionClass;
    },
    checkedAll(course) {
      course.checkedAll = !course.checkedAll;
      this.displayStudents.forEach(student => {
        student.is_checked = course.checkedAll;
      });
      this.getAvg();
    },
    checked(student) {
      student.is_checked = !student.is_checked;
      let count = 0;
      this.displayStudents.forEach(student => {
        if (student["is_checked"] == true) {
          count++;
        }
      });
      if (this.displayStudents.length === count) {
        this.course.checkedAll = true;
      } else {
        this.course.checkedAll = false;
      }
      this.getAvg();
    },
    getAvg() {
      let e_old = 0;
      let m_old = 0;
      let t_old = 0;
      (this.course.session_class_exams || []).forEach((exam, index) => {
        exam.e_total = 0;
        exam.m_total = 0;
        exam.t_total = 0;
        exam.e_up = 0;
        exam.m_up = 0;
        exam.t_up = 0;
        exam["count"] = 0;
        // exam["onlineCount"] = 0;
        (this.displayStudents || []).forEach(student => {
          if (student.is_checked) {
            if (
              student.student_exams &&
              student.student_exams["Test" + index] &&
              student.student_exams["Test" + index].user_exam &&
              student.student_exams["Test" + index].user_exam.result
            ) {
              exam["count"]++;
              exam.e_total +=
                student.student_exams[
                  "Test" + index
                ].user_exam.result.english_score;
              exam.m_total +=
                student.student_exams[
                  "Test" + index
                ].user_exam.result.math_score;

              exam.t_total +=
                student.student_exams["Test" + index].user_exam.total_score;
            }
          }
        });

        if (t_old > 0) {
          if (exam["count"] > 0) {
            exam.e_up = this.showScore(exam.e_total, exam["count"]) - e_old;
            exam.m_up = this.showScore(exam.m_total, exam["count"]) - m_old;
            exam.t_up = exam.r_up + exam.g_up + exam.m_up;
          }
        }
        e_old = this.showScore(exam.e_total, exam["count"]);
        m_old = this.showScore(exam.m_total, exam["count"]);
        t_old = e_old + m_old;
      });
      this.displayStudents = JSON.parse(JSON.stringify(this.displayStudents));
    },
    showScore(total, count) {
      let score = "";
      if (count > 0) {
        score = Math.round(total / count);
      }
      return score;
    },
    setLink(code) {
      console.log(code);
      this.linkType = "Class";
      this.link = `${baseDomainPath}student/classes/join?code=${code}`;
      this.showLink = true;
    },
    setTestLink(classExamId, title) {
      this.linkType = "Test";
      this.linkTypeTitle = this.getCompanyExamTitle(title);
      this.link = `${baseDomainPath}class/exam/${classExamId}`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    },
    async getClassExamOverView(id) {
      if (this.isStudent) {
        const res = await Class.getMyClassExamOverView({
          session_class_id: id
        });
        this.displayStudents = res.session_class_students;
        this.weeklyScores = res.weekly_min_max_scores;
      } else {
        const res = await Class.getClassExamOverView({
          session_class_id: id
        });
        this.displayStudents = res.session_class_students;
        this.weeklyScores = res.weekly_min_max_scores;
      }
    },
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.course = res;
    },
    async testToStudents(week) {
      let student_user_ids = [];

      if (this.displayStudents && this.displayStudents.length > 0) {
        this.displayStudents.forEach(student => {
          if (student.is_checked) {
            student_user_ids.push(student.id);
          }
        });
      }
      if (student_user_ids && student_user_ids.length > 0) {
        this.$confirm(
          this.$t("Courses.Allow selected student(s) access to the test?"),
          "",
          {
            confirmButtonText: this.$t("alert.confirm"),
            cancelButtonText: this.$t("alert.cancel"),
            type: "warning"
          }
        )
          .then(async () => {
            await Class.testToStudents(this.courseId, {
              which_week: week,
              student_user_ids: student_user_ids
            });
            this.reset();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t("alert.Canceled")
            });
          });
      } else {
        this.$message({
          message: this.$t("Courses.Please choose at least 1 student"),
          type: "warning"
        });
      }
    },
    async mailToStudents(week) {
      let student_user_ids = [];

      if (this.displayStudents && this.displayStudents.length > 0) {
        this.displayStudents.forEach(student => {
          if (student.is_checked) {
            student_user_ids.push(student.id);
          }
        });
      }
      if (student_user_ids && student_user_ids.length > 0) {
        this.$confirm(
          this.$t("Courses.Send test link to these students?"),
          "",
          {
            confirmButtonText: this.$t("alert.confirm"),
            cancelButtonText: this.$t("alert.cancel"),
            type: "warning"
          }
        )
          .then(async () => {
            let student_user_ids = [];

            this.displayStudents.forEach(student => {
              if (student.is_checked) {
                student_user_ids.push(student.id);
              }
            });

            await Class.mailToStudents(this.courseId, {
              which_week: week,
              student_user_ids: student_user_ids
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t("alert.Canceled")
            });
          });
      } else {
        this.$message({
          message: this.$t("Courses.Please choose at least 1 student"),
          type: "warning"
        });
      }
    },
    async setExamUser(user_id, week) {
      let student_user_ids = [user_id];
      await Class.testToStudents(this.courseId, {
        which_week: week,
        student_user_ids: student_user_ids
      });
      this.reset();
    },
    async sendExamMail(user_id, week) {
      let student_user_ids = [user_id];
      await Class.mailToStudents(this.courseId, {
        which_week: week,
        student_user_ids: student_user_ids
      });
    },
    showSection(index) {
      this.$router.replace({
        name: `${index}`,
        params: {
          id: this.$route.params.id
        }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .select-popper {
  z-index: 9999 !important;
  top: auto !important;
  left: auto !important;
}
.add-button {
  width: 150px;
}
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.table-box {
  overflow: auto;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}
.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
table td {
  vertical-align: middle;
}
</style>
