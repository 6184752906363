<template>
  <div>
    <div>
      <Breadcrumb>
        <template slot="restPage">
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'CompanyList'
              }"
            >
              Companies
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            {{ myTitle }}
          </el-breadcrumb-item>
        </template>
      </Breadcrumb>
      <Heading heading="h4" :content="myTitle"></Heading>
      <small> View {{ myTitle }} </small>
      <hr />
      <Pro
        v-if="info"
        title="Annual Pro Accounts for Users"
        :point="info.subscription_quota"
      />
      <hr />
      <el-checkbox-group v-model="roles">
        <el-checkbox-button :label="`Admins`">Admins</el-checkbox-button>
        <el-checkbox-button :label="`Educators`">Educators</el-checkbox-button>
        <el-checkbox-button :label="`Students`">Students</el-checkbox-button>
      </el-checkbox-group>
      <el-input
        class="mt-3"
        ref="filterInput"
        placeholder="Name / Email"
        v-model="search"
        @keyup.enter.native="searchResult"
      >
        <el-button
          el-button
          slot="append"
          icon="el-icon-search"
          @click="searchResult"
        >
          Search
        </el-button>
      </el-input>
      <div class="text-center mt-3 mb-3">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="10"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-table
        class="mt-4"
        :data="users"
        style="min-width: 100%"
        border
        v-if="users"
      >
        <el-table-column label="Name">
          <template slot-scope="scope">
            <b>
              {{ user.displayName(scope.row.first_name, scope.row.last_name) }}
            </b>
          </template>
        </el-table-column>
        <el-table-column label="Email" width="350">
          <template slot-scope="scope">
            <b>{{ scope.row.email }}</b>
          </template>
        </el-table-column>
        <el-table-column label="Plan" width="120">
          <template slot-scope="scope">
            <b
              v-if="(scope.row.all_roles || []).includes('pro')"
              style="color: orange;"
            >
              <span class="proIcon">
                <i class="fas fa-crown" />
                Pro
              </span>
            </b>
            <b v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="Click to Upgrade 1 Month Pro"
                placement="top"
                v-if="10 > 0"
              >
                <el-button
                  type="info"
                  @click="assignYearlyPro(scope.row.id)"
                  size="small"
                  disabled
                >
                  Free
                </el-button>
              </el-tooltip>
            </b>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Role" width="200">
          <template slot-scope="scope">
            <span v-for="(role, index) in scope.row.all_roles" :key="index">
              <el-tooltip
                class="item"
                effect="dark"
                content="Admin"
                placement="top"
              >
                <el-tag
                  v-if="role === 'company-admin'"
                  type="success"
                  class="mr-2"
                >
                  <i class="fas fas fa-building"></i>
                </el-tag>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Teacher"
                placement="top"
              >
                <el-tag v-if="role === 'teacher'" type="success" class="mr-2">
                  <i class="fas fa-chalkboard-teacher"></i>
                </el-tag>
              </el-tooltip>
            </span>
            <el-button type="text" @click="selectRole(scope.row)">
              <i class="fa fa-edit"></i>
            </el-button>
          </template>
        </el-table-column> -->
        <el-table-column label="Date Registered" width="150">
          <template slot-scope="scope">
            {{ instant.formatGoogleTime(scope.row.created_at) }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="Action" width="80">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="Remove" placement="top">
              <el-button
                type="danger"
                size="small"
                @click="removeStudent(scope.row.id)"
              >
                <i class="fas fa-user-minus"></i>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="text-center mt-3">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="10"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import Pro from "@/views/company/components/Pro.vue";
import Company from "@/views/company/apis/company.js";

export default {
  metaInfo() {
    return {
      title: "Company" + this.myTitle + " - " + this.CompanyName
    };
  },

  components: { Breadcrumb, Pro },

  mixins: [],

  props: [],
  data() {
    return {
      info: null,
      users: null,
      search: "",
      roles: ["Admins", "Educators", "Students"],
      resultsTotal: 1,
      pageSize: 10
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    myTitle() {
      return "Users";
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    this.getInfo();
    this.getCompanyUsers();
  },

  methods: {
    async getInfo() {
      const res = await Company.getCompanyDetail(this.$route.params.id);
      this.info = res;
    },
    async getCompanyUsers() {
      const res = await Company.getCompanyUsers(this.$route.params.id);
      this.users = res.data;
      this.resultsTotal = res.total;
    },
    searchResult() {},
    selectRole(user) {
      this.activeUser = null;
      this.activeUser = user;
      this.showRole = true;
      if (user.all_roles.indexOf("teacher") > -1) {
        this.isTeacher = true;
      } else {
        this.isTeacher = false;
      }
    }
  }
};
</script>

<style scoped></style>
