<template>
  <div>
    <Heading heading="h4" :content="$t('TeacherCourses.Info')"></Heading>
    <hr />
    <el-form label-width="140px">
      <el-form-item
        v-if="course && course.teacher_users"
        :label="$t('TeacherCourses.Teacher')"
        prop="title"
      >
        <b
          class="mr-1"
          v-for="(teacher, index) in course.teacher_users"
          :key="teacher.id"
        >
          <router-link
            v-if="isAdmin"
            :to="{ name: 'AdminViewUser', params: { id: teacher.id } }"
          >
            {{ user.displayName(teacher.first_name, teacher.last_name) }}
          </router-link>
          <template v-else>
            <span class="text-success">
              {{ user.displayName(teacher.first_name, teacher.last_name) }}
            </span>
          </template>
          <span v-if="index < course.teacher_users.length - 1">
            ,
          </span>
        </b>
      </el-form-item>
      <el-form-item :label="$t('TeacherCourses.Class Name')" prop="title">
        <b class="text-success">
          {{ course.name }}
        </b>
      </el-form-item>
      <el-form-item
        :label="$t('TeacherCourses.Class Description')"
        prop="title"
      >
        <span class="text-success">
          <b v-if="course.description">{{ course.description }}</b>
          <b v-else>-</b>
        </span>
      </el-form-item>
      <el-form-item :label="$t('TeacherCourses.Class Dates')" prop="date">
        <b class="text-success">
          <template v-if="course.started_at">
            <span v-if="course.started_at">{{
              instant.formatGoogleTime(course.started_at)
            }}</span>
            <span v-if="course.ended_at">
              ~
              {{ instant.formatGoogleTime(course.ended_at) }}
            </span>
          </template>
          <template v-else>
            -
          </template>
        </b>
      </el-form-item>
      <el-form-item :label="$t('TeacherCourses.Class Time')" prop="time">
        <b class="text-success">
          <template v-if="course.started_time">
            <span>{{ course.started_time }}</span>
            <span v-if="course.ended_time">
              ~
              {{ course.ended_time }}
            </span>
          </template>
          <template v-else>
            -
          </template>
        </b>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant, helper } from "@ivy-way/material";;
import Class from "@/views/courses/apis/Add.js";

export default {
  metaInfo() {},

  components: {},

  mixins: [],
  props: [],
  data() {
    return {
      course: null,
      showLink: false
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    helper() {
      return helper;
    }
  },
  watch: {},

  mounted() {
    this.getClass(this.$route.params.id);
  },

  methods: {
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.course = res;
    }
  }
};
</script>

<style scoped></style>
