<template>
  <div>
    <Heading heading="h4" content="Materials" class="mb-4">
      <router-link
        v-if="isEducator"
        slot="button"
        :to="{
          name: 'EducatorClassMaterialsEdit',
          params: { id: $route.params.id }
        }"
      >
        <el-button type="primary" size="mini">
          <i class="fa fa-edit"></i>
        </el-button>
      </router-link>
    </Heading>
    <hr />
    <el-card class="mb-4" v-if="Files">
      <div class="video-box" v-if="Files.length > 0">
        <div class="edu-play-left">
          <div class="section-card-list">
            <FilesItem :Files="Files" @viewFile="viewFile" :isView="true"></FilesItem>
          </div>
        </div>
        <div class="edu-play-right" v-if="file">
          <FileDetail :file="file" :isView="true" />
        </div>
      </div>
      <el-empty v-else description="No Materials"></el-empty>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import roleMixin from "@/mixins/role";
import Materials from "@/views/courses/materials/apis/materials";
import FilesItem from "@/views/courses/materials/components/Files";
import FileDetail from "@/views/courses/materials/components/FileDetail";

export default {
  components: { FilesItem, FileDetail },

  mixins: [roleMixin],

  props: [],
  data() {
    return {
      Files: null,
      file: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    isEducator() {
      return this.$route.meta && this.$route.meta.role
        ? this.$route.meta.role === "educator"
        : false;
    },
    isStudent() {
      return this.$route.meta && this.$route.meta.role
        ? this.$route.meta.role === "student"
        : false;
    },
    myTitle() {
      return this.course ? this.course.name : "課程的標題";
    },
    moment() {
      return moment;
    }
  },
  watch: {
    subjectIndexNow() {
      this.subjectNow = this.subjectIndexNow;
    },
    materialsIndexNow() {
      this.materialsNow = this.materialsIndexNow;
    }
  },

  async mounted() {
    this.getFiles();
  },

  methods: {
    async getFiles() {
      const Files = await Materials.getFiles({
        teaching_class_id: this.$route.params.id,
        parent_id: 0
      });
      this.Files = Files;
    },
    async viewFile(file) {
      this.file = null;
      const res = await Materials.getFileDetail(file.id);
      this.file = res;
    }
  }
};
</script>

<style scoped>
.video-box {
  display: flex;
  /* margin-top: 1rem; */
}
.edu-play-left {
  min-width: 28%;
  max-width: 500px;
  border-right: 1px solid #ebeef5;
  /* max-height: 50rem;
    overflow: scroll; */
}
.section-card-list {
  height: 50rem;
  overflow: auto;
}
.edu-play-right {
  box-sizing: border-box;
  padding: 0 0 10px 0;
  flex: 1;
  padding-bottom: 3rem;
  background-color: #fafafa;
}
::v-deep .el-card__body {
  padding-left: 0;
  padding: 0;
}
</style>
