<template>
  <el-form
    class="login-form"
    :rules="rules"
    :model="model"
    ref="form"
    :validate-on-rule-change="false"
  >
    <div class="row">
      <div class="col-12">
        <el-form-item prop="role">
          <el-radio-group v-model="model.register_type" style="display: flex;">
            <el-radio label="student" class="m-0" border>
              <i class="fas fa-user-graduate"></i>
              STUDENT
            </el-radio>
            <el-radio label="teacher" class="m-0 ml-4" border>
              <i class="fas fa-chalkboard-teacher"></i>
              EDUCATOR
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <el-form-item prop="first_name">
          <el-input
            v-model="model.first_name"
            :placeholder="$t('registerDetail.firstName')"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
      </div>
      <div class="col-md-6">
        <el-form-item prop="last_name">
          <el-input
            v-model="model.last_name"
            :placeholder="$t('registerDetail.lastName')"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
      </div>
    </div>
    <el-form-item prop="email" :error="errors.email">
      <el-alert
        v-show="needLogin"
        type="error"
        :closable="false"
        style="margin-bottom:10px"
      >
        The email has already been taken,
        <a :href="'/login?email=' + model.email">Pelase click here to login</a>.
      </el-alert>
      <el-input
        v-model="model.email"
        :placeholder="$t('login.email')"
        prefix-icon="el-icon-message"
      ></el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        v-model="model.password"
        :placeholder="$t('login.password')"
        type="password"
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item prop="verifyPassword">
      <el-input
        v-model="model.verifyPassword"
        :placeholder="$t('login.verify_password')"
        type="password"
        prefix-icon="el-icon-lock"
      ></el-input>
    </el-form-item>
    <el-form-item prop="g-recaptcha-response">
      <div>
        <GRecaptcha
          ref="recaptcha"
          sitekey="6LfE5TAqAAAAAG1FnYC-aUhJBTXAqZLxN1HyGqkO"
          @getValidateCode="getValidateCode"
        ></GRecaptcha>
      </div>
    </el-form-item>
    <el-form-item align="right">
      <el-button
        type="primary"
        style="width: 100%;"
        block
        @click="handleRegister"
        >{{ $t("login.register") }}</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import formMixin from "@/mixins/form";
import GRecaptcha from "@/components/GRecaptcha";

export default {
  mixins: [formMixin],
  components: {
    GRecaptcha
  },
  data: () => {
    return {
      model: {
        register_type: "student",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        verifyPassword: "",
        captcha: "",
        referral_code: "",
        "g-recaptcha-response": ""
      },
      needLogin: false,
      errors: {
        email: "",
        captcha: ""
      },
      validateCode: false,
      isNext: false,
      disabled: true
    };
  },
  computed: {
    rules() {
      const checkVerifyPassword = (rule, value, callback) => {
        if (value !== this.model.password) {
          callback(new Error(this.$t("register.please_same_verify_password")));
        } else {
          callback();
        }
      };

      const checkEmail = (rule, value, callback) => {
        // 验证邮箱的正则表达式
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        if (regEmail.test(value)) {
          callback(new Error(this.$t("register.please_valid_email")));
        } else {
          callback();
        }
      };

      return {
        first_name: [
          {
            required: true,
            message: this.$t("register.please_input"),
            trigger: "blur"
          }
        ],
        last_name: [
          {
            required: true,
            message: this.$t("register.please_input"),
            trigger: "blur"
          }
        ],
        email: [
          // { validator: checkEmail, trigger: "blur" },
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ],
        verifyPassword: [{ validator: checkVerifyPassword, trigger: "blur" }],
        captcha: [
          {
            required: true,
            message: this.$t("register.please_input_captcha"),
            trigger: "blur"
          }
        ],
        "g-recaptcha-response": [
          {
            required: true,
            message: this.$t("register.please_input_captcha"),
            trigger: "blur"
          }
        ]
      };
    },
    ...mapGetters({
      captchaImg: "captcha/img",
      captchaKey: "captcha/key"
    })
  },

  mounted() {
    if (this.$route.query.referral_code) {
      this.model.referral_code = this.$route.query.referral_code;
    }
  },

  methods: {
    ...mapActions("user", ["register"]),
    getValidateCode(token) {
      // this.t = value;
      console.log("token:" + token);
      this.model["g-recaptcha-response"] = token;
      this.disabled = false;
    },
    async handleRegister() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }

      const {
        register_type,
        first_name,
        last_name,
        email,
        password,
        captcha,
        referral_code
      } = this.model;
      const registerReq = {
        register_type,
        first_name,
        last_name,
        email,
        password,
        captcha,
        referral_code
      };
      registerReq["g-recaptcha-response"] = this.model["g-recaptcha-response"];

      try {
        await this.register(registerReq);
      } catch (error) {
        if (error.status === 422) {
          this.needLogin = true;
        }
        return error;
      }

      await this.$message({
        message: this.$t("message.register_success"),
        type: "success"
      });
      await this.$store.dispatch("user/getProfile");

      let nextRoutePath = localStorage.getItem("lastAccessRoutePath");
      localStorage.setItem("lastAccessRoutePath", "");
      if (!nextRoutePath || nextRoutePath == "/home") {
        this.$router.replace({ name: "SATList" });
      } else {
        this.$router.replace({ path: nextRoutePath });
      }
    },
    refreshCaptcha() {
      this.$store.dispatch("captcha/getCaptcha");
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        this.errors = {
          email: "",
          captcha: ""
        };
        this.needLogin = false;
      }
    }
  }
};
</script>
<style scoped>
.register-row {
  height: 80vh;
}

.el-card /deep/ .el-card__header {
  background: #ff770c;
}

.clearfix {
  color: white;
}

.el-button {
  outline: none;
}
::v-deep .el-form-item__content {
  line-height: 2;
}
</style>
