import ApiService from "@/common/api.service";

export default {
  upgradePlan(data) {
    return ApiService.post("pro/payment-link", data);
  },
  getPayUrl(data) {
    return ApiService.query("stripe/subscription", data);
  }
};
